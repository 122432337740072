import * as ini from "ini";
export const getConfig = async () => {
  return await fetch("/config/config.ini", {
    method: "get",
    headers: {
      "Cache-Control":
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
      Pragma: "no-cache",
      Expires: "0",
      "content-type": "application/text",
    },
  })
    .then((res) => res.text())
    .then((res) => {
      const configObj = ini.decode(res);
      const config = {};
      for (const prop in configObj) {
        if (!configObj.hasOwnProperty(prop)) {
          continue;
        }
        config[prop] = configObj[prop];
      }

      return config;
    });
};
