import Link from "@material-ui/core/Link";
import * as React from "react";

export default class Logo extends React.Component {
  render() {
    return (
      <Link href="/" component="a">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62mm"
          height="8mm"
          viewBox="0 0 219.685 28.346"
        >
          <defs>
            <clipPath id="a">
              <path d="M541 410h53.555v22H541zm0 0" />
            </clipPath>
            <clipPath id="b">
              <path d="M541.363 423h53.192v4h-53.192zm0 0" />
            </clipPath>
            <clipPath id="c">
              <path d="M541.363 415h53.192v5h-53.192zm0 0" />
            </clipPath>
            <clipPath id="d">
              <path d="M541.363 420h53.192v2h-53.192zm0 0" />
            </clipPath>
          </defs>
          <g
            clipPath="url(#a)"
            transform="matrix(1.25 0 0 1.25 -524.7 -512.13)"
          >
            <path
              d="M541.363 431.18h53.192v-20.274h-53.192zm0 0"
              fill="#211e1e"
            />
          </g>
          <g
            clipPath="url(#b)"
            transform="matrix(1.25 0 0 1.25 -524.7 -512.13)"
          >
            <path
              d="M541.36 425.379v-.48c8.097-1.036 17.152-1.774 26.59-1.774 9.53 0 18.5.7 26.655 1.754.047.008 17.676 1.191 17.723 1.2v.48c-.062-.004-17.703-1.188-17.762-1.188-8.355-.426-17.254-.77-26.617-.77-9.332 0-18.277.356-26.59.778"
              fill="#7fd4f5"
            />
          </g>
          <g
            clipPath="url(#c)"
            transform="matrix(1.25 0 0 1.25 -524.7 -512.13)"
          >
            <path
              d="M541.36 417.46v-.48c8.406.426 17.16.797 26.59.797 9.222 0 18.269-.367 26.503-.78.098-.009 17.817-1.192 17.914-1.196v.48c-.074.008-17.765 1.196-17.84 1.207-8.043 1.02-17.226 1.766-26.578 1.766-9.586 0-18.394-.73-26.59-1.793"
              fill="#7fd4f5"
            />
          </g>
          <g
            clipPath="url(#d)"
            transform="matrix(1.25 0 0 1.25 -524.7 -512.13)"
          >
            <path
              d="M541.36 421.438v-.489c8.187-.316 17.148-.5 26.59-.5 9.394 0 18.437.184 26.577.496.075 0 17.805.004 17.88.008v.48c-.06.005-17.778.005-17.837.009-8.152.312-17.215.476-26.62.476-9.473 0-18.4-.164-26.59-.48"
              fill="#7fd4f5"
            />
          </g>
          <path
            d="M10.397 9.276H1.193v1.87H4.62v8.335h2.354v-8.335h3.423zm0 0M16.486 9.276V19.48h2.358v-4.146h.679c1.274 0 1.65 1.958 2.66 4.146h2.589c-1.328-2.993-1.621-4.248-2.588-4.776 1.079-.537 1.62-1.372 1.62-2.495 0-1.528-1.151-2.934-3.09-2.934zm2.358 1.87h1.191c.889 0 1.343.39 1.343 1.152 0 .87-.635 1.167-1.289 1.167h-1.245zm0 0M36.056 9.276h-2.73L29.513 19.48h2.495l.708-2.022h4.224l.776 2.022h2.54zm.19 6.406h-2.905l1.382-3.97zm0 0M55.065 9.276h-2.359v6.24l-5.044-6.24H45.5V19.48h2.359v-6.538l5.224 6.538h1.983zm0 0M67.706 9.935c-1.191-.552-2.148-.791-3.203-.791-2.187 0-3.603 1.167-3.603 2.93 0 3.667 4.81 3.051 4.81 4.726 0 .6-.484.947-1.388.947-1.04 0-1.87-.346-3.227-1.318v2.197c.942.523 1.758.992 3.408.992 2.11 0 3.633-1.138 3.633-3.101 0-3.56-4.81-3.125-4.81-4.668 0-.508.542-.835 1.387-.835.8 0 1.621.254 2.993.913zm0 0M74.376 9.276V19.48h2.354v-4.102h1.87c2.178 0 3.12-1.645 3.12-3.08 0-1.392-.972-3.023-3.135-3.023zm2.354 1.87h.83c1.274 0 1.733.317 1.733 1.182 0 .761-.386 1.181-1.47 1.181H76.73zm0 0M96.476 14.378c0-2.45-1.846-5.234-5.405-5.234-3.565 0-5.42 2.764-5.42 5.234 0 2.603 1.953 5.235 5.42 5.235 3.393 0 5.405-2.618 5.405-5.235m-2.427 0c0 1.87-1.191 3.11-2.978 3.11-1.802 0-2.994-1.24-2.994-3.11 0-1.87 1.192-3.11 2.994-3.11 1.787 0 2.978 1.24 2.978 3.11M107.452 12.957l2.935 6.656h1.016l4.14-10.337h-2.495l-2.217 5.64-2.422-5.64h-1.899l-2.427 5.64-2.231-5.64h-2.495l4.16 10.337h1.01zm0 0M127.35 17.61h-4.614v-2.455h4.311v-1.87h-4.311v-2.14h4.521v-1.87h-6.875v10.206h6.968zm0 0M133.326 9.276V19.48h2.354v-4.146h.679c1.274 0 1.65 1.958 2.66 4.146h2.594c-1.329-2.993-1.622-4.248-2.593-4.776 1.084-.537 1.62-1.372 1.62-2.495 0-1.528-1.147-2.934-3.09-2.934zm2.354 1.87h1.191c.889 0 1.348.39 1.348 1.152 0 .87-.64 1.167-1.29 1.167h-1.25zm0 0"
            fill="#211e1e"
          />
        </svg>
      </Link>
    );
  }
}
