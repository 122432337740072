import { makeStyles } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ACSlogo from "./images/acs_logo.svg";
import { default as AUFLSlogo } from "./images/bar-chart.svg";
import NZGBlogo from "./images/nzgblogo.svg";
import POCPlogo from "./images/pocp_logo.svg";

const useStyles = makeStyles({
  imageIcon: {
    fillOpacity: 0.8,
    display: "flex",
    height: "inherit",
    width: "inherit",
  },
  iconRoot: {
    textAlign: "center",
  },
});

export const AuflsIcon = () => {
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src={AUFLSlogo} alt="AUFLS" />
    </Icon>
  );
};

export const AcsIcon = () => {
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src={ACSlogo} alt="ACS" />
    </Icon>
  );
};

export const PocpIcon = () => {
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src={POCPlogo} alt="POCP" />
    </Icon>
  );
};

export const NzgbIcon = () => {
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src={NZGBlogo} alt="NZGB" />
    </Icon>
  );
};

export const DandeIcon = () => {
  const classes = useStyles();
  return <AccountTreeIcon classes={{ root: classes.iconRoot }} />;
};
