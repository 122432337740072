import createPalette from "@material-ui/core/styles/createPalette";

const COLOUR_TP_RGBA: string = "rgba(0,115,153, 0.2)";
const COLOUR_TP_RGBA6: string = "rgba(0,115,153, 0.6)";
const COLOUR_TP_RGBA9: string = "rgba(0,115,153, 0.9)";

export const Palette = createPalette({
  primary: {
    main: "#343a40",
  },
  secondary: {
    light: COLOUR_TP_RGBA9,
    main: COLOUR_TP_RGBA6,
    dark: COLOUR_TP_RGBA,
    contrastText: "#fff",
  },
  background: {
    default: "#fcfcfe",
  },
  grey: {
    A100: "#FFFFFF",
    A200: "#F2F2F2",
    A400: "#D6D6D6",
    A700: "#54656D",
  },
});
