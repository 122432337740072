import { stringify } from "query-string";
import { HttpError } from "react-admin";
import inMemoryJwtManager from "../auth/inMemoryJwtManager";

export interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  };
}

export interface IFetchResponse {
  status: number;
  statusText?: string;
  headers: any;
  body?: string;
  json: any;
}

export const createHeadersFromOptions = (options: Options): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: "application/json",
    })) as Headers;
  if (
    !requestHeaders.has("Content-Type") &&
    !(options && (!options.method || options.method === "GET")) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/json");
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set("Authorization", options.user.token);
  }

  return requestHeaders;
};

export const fetchJson = async (
  url: string,
  options: Options = {}
): Promise<IFetchResponse> => {
  const token = await inMemoryJwtManager.waitForTokenRefresh();
  if (token) {
    options.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    };
  }

  const requestHeaders = createHeadersFromOptions(options);
  const response = await fetch(url, { ...options, headers: requestHeaders });
  const responseText = await response.text();
  const status = response.status;
  const statusText = response.statusText;
  const headers = response.headers;
  const body = responseText;

  let json;
  try {
    json = JSON.parse(body);
  } catch (e) {
    // not json, no big deal
  }

  if (status < 200 || status >= 300) {
    throw new HttpError(json?.message || statusText, status, json);
  }

  return { status, headers, body, json };
};

export const queryParameters = stringify;

const isValidObject = (value) => {
  if (!value) {
    return false;
  }

  const isArray = Array.isArray(value);
  const isBuffer = typeof Buffer !== "undefined" && Buffer.isBuffer(value);
  const isObject = Object.prototype.toString.call(value) === "[object Object]";
  const hasKeys = !!Object.keys(value).length;

  return !isArray && !isBuffer && isObject && hasKeys;
};

export const flattenObject = (value: string, path: string[] = []) => {
  if (isValidObject(value)) {
    return Object.assign(
      {},
      ...Object.keys(value).map((key) =>
        flattenObject(value[key], path.concat([key]))
      )
    );
  } else {
    return path.length ? { [path.join(".")]: value } : value;
  }
};
