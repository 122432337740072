import { AVATAR_KEY, PERMISSIONS_KEY } from "../../constants";
import { IOrgPermission } from "../../types";
import { localStorageService } from "../util/LocalStorageService";
import { AuthModule, ForceLoginFallback } from "./authModule";

export function isGuestUser(): boolean {
  const permissions = localStorageService.getItem<IUserPermissions>(
    PERMISSIONS_KEY
  );

  return permissions?.fullname === "Guest";
}

export interface IUserPermissions {
  appPermissions: Array<string>;
  orgPermissions: Array<IOrgPermission>;
  fullname: string;
  email: string;
  acceptedTsAndCs: string | null;
}

export interface ISavedPermissions {
  [app: string]: ISelectedAppPermissions;
}

export interface ISelectedAppPermissions {
  appPermissions: Array<string>;
  orgPermission?: IOrgPermission;
}

export interface IAvatar {
  avatar?: string;
}

const getLoginFailureMessage = (err) => {
  if (err.message?.includes("AADSTS50105")) {
    /*
     * AADSTS50105 is returned when a user has a valid TP identity, but
     * has not been added to a role for the application in the Azure Oauth Enterprise App.
     * This is controlled by app property 'User assignment required?' in Azure AD, and
     * it was decided that for the portal this should be set to 'Yes' to avoid
     * proliferation of users in the identity store who might not have a genuine reason
     * to use the portal.
     */
    return "portal.error.loginFailedNoPermission";
  } else {
    return "portal.error.loginFailedWithError";
  }
};

const getAuthProvider = (provider: AuthModule) => {
  return {
    login: async (redirectUrl?: string) => {
      /* Do not redirect to /no-auth after a successful login */
      redirectUrl = redirectUrl?.replace("/no-auth", "/");

      try {
        return await provider.attemptSsoSilent(
          ForceLoginFallback.Always,
          redirectUrl
        );
      } catch (err) {
        console.error((err as Error).message);
        return Promise.reject(getLoginFailureMessage(err));
      }
    },

    /*
     * Attempt to perform silentLogin, but do NOT return an error if it fails.
     */
    silentLogin: async () => {
      try {
        return await provider.attemptSsoSilent(
          ForceLoginFallback.KnownAccountsOnly
        );
      } catch (err) {
        console.error((err as Error).message);
      }
    },

    catchRedirectAuth: async () => {
      try {
        return await provider.loadAuthModule();
      } catch (err) {
        console.error((err as Error).message);
        return Promise.reject(getLoginFailureMessage(err));
      }
    },

    logout: () => {
      provider.logout();
      return Promise.resolve();
    },

    checkError: (error: any) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        return Promise.reject({
          redirectTo: "/no-auth",
          logoutUser: false,
          message: false,
        });
      }
      return Promise.resolve();
    },

    checkAuth: () => {
      if (isGuestUser() || provider.getIdTokenClaims()) {
        return Promise.resolve();
      }
      return Promise.reject();
    },

    getPermissions: () => {
      const permissions = localStorageService.getItem<IUserPermissions>(
        PERMISSIONS_KEY
      );
      if (permissions) {
        return Promise.resolve(permissions);
      } else {
        return Promise.resolve();
      }
    },

    setPermissions: (permissions: IUserPermissions) => {
      localStorageService.setItem<IUserPermissions>(
        PERMISSIONS_KEY,
        permissions
      );
      return permissions;
    },

    getIdentity: async () => {
      if (isGuestUser() && !provider.idTokenIsValid()) {
        return Promise.resolve({ id: "guest", fullName: "Guest" });
      }

      try {
        if (
          !localStorageService.getItem<IAvatar>(AVATAR_KEY) &&
          provider.idTokenIsValid()
        ) {
          const avatar = await provider.getAvatar();
          localStorageService.setItem<IAvatar>(AVATAR_KEY, {
            avatar,
          } as IAvatar);
        }
      } catch (error) {}

      try {
        const avatar = localStorageService.getItem<IAvatar>(AVATAR_KEY);
        const accountInfo = provider.getAccountInfo();

        return Promise.resolve({
          id: accountInfo?.username,
          fullName: accountInfo?.name,
          avatar: avatar?.avatar,
        });
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },

    getIdTokenClaims: (): object | undefined => {
      return provider.getIdTokenClaims();
    },

    hasValidIdToken: (): boolean => {
      return provider.idTokenIsValid();
    },
  };
};

export default getAuthProvider;
