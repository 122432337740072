import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import Logo from "./layout/Logo";

export interface ILoadingProps {
  isLoading: boolean;
  message?: string;
  contents?: JSX.Element;
}

const Loading = (props: ILoadingProps) => {
  let contents;
  if (props.isLoading) {
    contents = <CircularProgress />;
  } else if (props.contents) {
    contents = props.contents;
  } else {
    contents = <Typography align="center">{props.message}</Typography>;
  }
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item md={3}>
        <Logo />
      </Grid>
      <Grid item md={3}>
        {contents}
      </Grid>
    </Grid>
  );
};

export default Loading;
