import { Overrides } from "@material-ui/core/styles/overrides";
import { Palette } from "./palette";

export const MuiOverrides: Overrides = {
  MuiTypography: {
    root: {
      fontFamily: `"Calibri", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  },
  MuiDrawer: {
    root: {
      RaSidebar: {
        width: 300, // The default value is 240
        closedWidth: 70, // The default value is 55
        border: "1px solid red",
      },
    },
  },
  MuiRadio: {
    root: {
      "&$hover": {
        backgroundColor: "transparent",
      },
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: "none",
    },
    root: {
      border: "1px solid #e0e0e3",
      backgroundClip: "padding-box",
    },
  },
  MuiButton: {
    contained: {
      backgroundColor: "#fff",
      color: Palette.secondary.dark,
      boxShadow: "none",
    },
  },
  MuiAppBar: {
    colorSecondary: {
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "#fff",
    },
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: "#f5f5f5",
    },
    barColorPrimary: {
      backgroundColor: "#d7d7d7",
    },
  },
  MuiCircularProgress: {
    colorPrimary: {
      color: Palette.secondary.main,
    },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      "&$disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
      },
    },
  },
  MuiTableCell: {
    head: {
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: "bold",
    },
  },
  MuiDialog: {
    paperWidthSm: {
      maxWidth: "800px",
    },
  },
  MuiTab: {
    root: {
      background: Palette.grey.A200,
      padding: "6px 20px",
      borderRight: "1px solid #e0e0e3",
      "&$selected": {
        background: Palette.grey.A100,
        fontWeight: "bold",
      },
    },
  },
  MuiCard: {
    root: {
      overflow: "visible",
    },
  },
};

export const RaOverrides: Overrides = {
  RaPaginationActions: {
    currentPageButton: {
      fontWeight: "bold",
      textDecorationLine: "underline",
    },
  },

  RaFileInput: {
    dropZone: {
      border: "1px dashed rgba(0,0,0,0.4)",
    },
  },

  RaFilterFormInput: {
    body: {
      alignItems: "center",
      flexDirection: "row-reverse",
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      "&:nth-last-child(2)": {
        borderRight: "none",
      },
      padding: "0 10px",
    },
    hideButton: {
      marginLeft: 2,
    },
    spacer: {
      width: 0,
    },
  },
  RaFilterForm: {
    form: {
      marginTop: 0,
      alignItems: "center",
    },
  },
  RaFilter: {
    button: {
      whiteSpace: "nowrap",
    },
  },
  RaAutocompleteArrayInput: {
    chipContainerFilled: {
      margin: "18px 12px 3px 0",
    },
    chip: {
      height: "auto",
      padding: "3px 0",
    },
  },
  RaMenuItemLink: {
    root: {
      borderLeft: "4px solid #fff",
    },
    active: {
      borderLeft: `4px solid ${Palette.secondary.main}`,
    },
  },
  RaSimpleFormIterator: {
    index: {
      display: "none",
    },
  },
  RaSidebar: {
    root: {
      backgroundCOlor: "#fcfcfe",
      zIndex: "999",
    },
    fixed: {
      backgroundColor: "#fcfcfe",
      zIndex: "999",
    },
  },
} as Overrides;
