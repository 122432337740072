import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import {
  ACS_APP_PATH,
  AUFLS_APP_PATH,
  DANDE_APP_PATH,
  NZGB_APP_PATH,
  POCP_APP_PATH,
} from "./constants";
import { backToPortal } from "./portal/links";
import Loading from "./reusable-components/Loading";

const PortalApp = React.lazy(() => import("./portal/App"));
const ERApp = React.lazy(() => import("./er/App"));
const ACSApp = React.lazy(() => import("./acs/App"));
const POCPApp = React.lazy(() => import("./pocp/App"));
const NZGBApp = React.lazy(() => import("./nzgb/App"));
const DANDEApp = React.lazy(() => import("./soregister/App"));

function PortalRoutes() {
  return (
    <Suspense fallback={<Loading isLoading={true} />}>
      <Switch>
        <Route path={ACS_APP_PATH} component={() => <ACSApp />} />
        <Route
          path={AUFLS_APP_PATH}
          component={() => <ERApp backToPortal={backToPortal} />}
        />
        <Route
          path={POCP_APP_PATH}
          component={() => <POCPApp backToPortal={backToPortal} />}
        />
        <Route
          path={NZGB_APP_PATH}
          component={() => <NZGBApp backToPortal={backToPortal} />}
        />
        <Route
          path={DANDE_APP_PATH}
          component={() => <DANDEApp backToPortal={backToPortal} />}
        />
        <Route path="/" component={() => <PortalApp />} />
      </Switch>
    </Suspense>
  );
}

export default PortalRoutes;
