import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  Configuration,
  EndSessionRequest,
  InteractionRequiredAuthError,
  LogLevel,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
  SsoSilentRequest,
} from "@azure/msal-browser";
import jwt_decode from "jwt-decode";
import { ACCOUNT_INFO_KEY } from "../../constants";
import { localStorageService } from "../util/LocalStorageService";
import inMemoryJwtManager from "./inMemoryJwtManager";

/**
 * When we attempt a silent SSO login, this option determines
 * if we fallback to force a login prompt when silent SSO fails.
 */
export enum ForceLoginFallback {
  Never,
  Always,
  KnownAccountsOnly,
}

/**
 * Configuration class for @azure/msal-browser
 */
const getMsalConfig = (config) => {
  return {
    auth: {
      authority: config.REACT_APP_MSAL_AUTHORITY,
      clientId: config.REACT_APP_MSAL_CLIENTID || "",
    },
    cache: {
      /* This configures where your cache will be stored */
      cacheLocation: "sessionStorage",
      /* Set this to "true" if you are having issues on IE11 or Edge */
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  } as Configuration;
};

type TTokenDTO = {
  preferred_username: any;
  exp: number;
};

function getTokenExpirationSeconds(token: string): number | undefined {
  const decoded: TTokenDTO = jwt_decode(token);
  if (decoded.exp === undefined) {
    return undefined;
  }
  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);
  return (date.valueOf() - new Date().valueOf()) / 1000;
}

export function isTokenExpired(token?: string | null): boolean {
  if (!token) {
    return true;
  }
  const secondsRemaining = getTokenExpirationSeconds(token) || 0;
  return secondsRemaining <= 0;
}

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {
  private myMSALObj: PublicClientApplication;
  private account: AccountInfo | null;
  private loginRequest: RedirectRequest;
  private profileRequest: RedirectRequest;
  private silentProfileRequest: SilentRequest;
  private silentLoginRequest: SsoSilentRequest;

  constructor(config) {
    const MSAL_CONFIG = getMsalConfig(config);
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);

    /* Used to provide userid hint for silentSSO */
    this.account = localStorageService.getItem<AccountInfo>(ACCOUNT_INFO_KEY);

    this.loginRequest = {
      scopes: ["openid", "email", "profile"],
    };

    this.profileRequest = {
      scopes: ["User.Read"],
    };

    this.silentProfileRequest = {
      scopes: ["openid", "profile", "User.Read"],
      forceRefresh: false,
    };

    this.silentLoginRequest = {
      loginHint: undefined,
    };

    inMemoryJwtManager.setAuthModule(this);
  }

  /**
   * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
   */
  private getAccount(): AccountInfo | null {
    const currentAccounts = this.myMSALObj.getAllAccounts();

    if (currentAccounts === null) {
      return null;
    }

    if (currentAccounts.length > 1) {
      // TODO: Add choose account code here
      console.log(
        "Multiple accounts detected, need to add choose account code."
      );
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }

    return null;
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   */
  async loadAuthModule(): Promise<any> {
    await this.myMSALObj
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
        this.handleResponse(resp);
      });
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts in the session and attempt to sign in.
   * @param response
   */
  handleResponse(response: AuthenticationResult | null) {
    if (response !== null) {
      this.account = response.account;
      if (this.account) {
        localStorageService.setItem<AccountInfo>(
          ACCOUNT_INFO_KEY,
          this.account
        );
      }
      inMemoryJwtManager.setToken(
        response.idToken,
        getTokenExpirationSeconds(response.idToken)
      );
    }
  }

  idTokenIsValid(): boolean {
    const idToken: string | null = inMemoryJwtManager.getToken();
    return idToken ? !isTokenExpired(idToken) : false;
  }

  getIdTokenClaims() {
    return this.idTokenIsValid() ? this.account?.idTokenClaims : undefined;
  }

  getAccountInfo(): AccountInfo | null {
    return this.account;
  }

  /**
   * Calls ssoSilent() to attempt silent flow. If it fails due to interaction required error,
   * it will prompt the user to login using a redirect if loginFallback directs it to.
   * ssoSilent() will cause a new idToken to be issued.
   *
   * @param loginFallback: Whether to trigger interactive login if user interaction is required
   * @param redirectStartPage: Specifiy where to redirect after logging in (defaults to window.href)
   */
  async attemptSsoSilent(
    loginFallback: ForceLoginFallback,
    redirectStartPage?: string
  ): Promise<AccountInfo | null> {
    this.silentLoginRequest.loginHint = this.account?.username;

    const ssoSilentRequest: SsoSilentRequest = {
      ...this.silentLoginRequest,
      redirectUri: window.location.origin,
    };

    await this.myMSALObj
      .ssoSilent(ssoSilentRequest)
      .then((resp: AuthenticationResult) => {
        this.handleResponse(resp);
        return this.account;
      })
      .catch((error) => {
        console.error("attemptSsoSilent error: " + error);
        /*
         * Note: we force a login if getAccount() returns true, to handle
         * the scenario when someone has 3rd party cookies blocked (which
         * happens by default with Chrome browsers in incognito mode).
         * If we don't do this, ssoSilent() fails with InteractionRequiredAuthError
         * even for scenarios we would expect them to be logged in for.
         */
        if (
          error instanceof InteractionRequiredAuthError ||
          error instanceof BrowserAuthError
        ) {
          if (
            loginFallback === ForceLoginFallback.Always ||
            (loginFallback === ForceLoginFallback.KnownAccountsOnly &&
              this.getAccount())
          ) {
            return this.login(redirectStartPage);
          }
        }
      });

    return this.account;
  }

  /**
   * Calls loginPopup or loginRedirect based on given signInType.
   * @param signInType
   */
  async login(redirectStartPage?: string): Promise<AccountInfo | null> {
    const loginRedirectRequest: RedirectRequest = {
      ...this.loginRequest,
      redirectUri: window.location.origin,
      redirectStartPage: redirectStartPage
        ? redirectStartPage
        : window.location.href,
    };

    this.myMSALObj.loginRedirect(loginRedirectRequest);
    return this.account;
  }

  /**
   * Logs out of current account.
   */
  logout(): void {
    let account: AccountInfo | undefined;
    if (this.account) {
      account = this.account;
    }
    const logOutRequest: EndSessionRequest = {
      account,
    };

    localStorageService.removeItem(ACCOUNT_INFO_KEY);

    this.myMSALObj.logoutRedirect(logOutRequest);
    inMemoryJwtManager.eraseToken();
  }

  async getAvatar(): Promise<string | ArrayBuffer | null> {
    const response = await this.getProfileTokenPopup().then((accessToken) => {
      return fetch("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", {
        method: "GET",
        headers: {
          "Content-Type": "image/jpg",
          Authorization: `Bearer ${accessToken}`,
        },
      });
    });

    const blob = await response.blob();
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    return reader.result;
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
   */
  async getProfileTokenRedirect(
    redirectStartPage?: string
  ): Promise<string | null> {
    if (this.account) {
      this.silentProfileRequest.account = this.account;
    }

    const profileRedirectRequest = {
      ...this.profileRequest,
      redirectUri: window.location.origin,
      redirectStartPage: redirectStartPage
        ? redirectStartPage
        : window.location.href,
    };

    return this.getTokenRedirect(
      this.silentProfileRequest,
      profileRedirectRequest
    );
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
   */
  async getProfileTokenPopup(): Promise<string | null> {
    if (this.account) {
      this.silentProfileRequest.account = this.account;
    }
    return this.getTokenPopup(this.silentProfileRequest, this.profileRequest);
  }

  /**
   * Gets a token silently, or falls back to interactive popup.
   */
  private async getTokenPopup(
    silentRequest: SilentRequest,
    interactiveRequest: PopupRequest
  ): Promise<string | null> {
    try {
      const response: AuthenticationResult = await this.myMSALObj.acquireTokenSilent(
        silentRequest
      );
      this.handleResponse(response);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return this.myMSALObj
          .acquireTokenPopup(interactiveRequest)
          .then((resp) => {
            this.handleResponse(resp);
            return resp.accessToken;
          })
          .catch((err) => {
            console.error(err);
            return null;
          });
      } else {
        console.error(e);
      }
    }

    return null;
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string | null> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
      this.handleResponse(response);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        this.myMSALObj
          .acquireTokenRedirect(interactiveRequest)
          .catch(console.error);
      } else {
        console.error(e);
      }
    }

    return null;
  }
}
