import { AuthModule, ForceLoginFallback, isTokenExpired } from "./authModule";

/*
 * This is from an example on
 * https://marmelab.com/blog/2020/07/02/manage-your-jwt-react-admin-authentication-in-memory.html,
 *
 * It has a tight coupling to AuthModule, where the refresh timeout will
 * call ssoSilentLogin() to refresh the identity token, and authModule
 * will save the identity token back to through setToken()
 */
const inMemoryJWTManager = () => {
  let inMemoryJWT = null;
  let isRefreshing: Promise<any> | undefined;
  let logoutEventName = "ocp.logout";
  let refreshTimeOutId;
  let authModule: AuthModule | null = null;

  const getAuthModule = () => authModule;

  const setAuthModule = (auth: AuthModule) => (authModule = auth);

  /* Sets a timer to renew the idToken 10 seconds before it expires */
  const refreshToken = (delay) => {
    const timeout = delay * 1000 - 10000;
    refreshTimeOutId = window.setTimeout(getRefreshedToken, timeout);
  };

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  /* Returns a promise that resolves to a token */
  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve(getToken());
    }
    return isRefreshing.then(() => {
      isRefreshing = undefined;
      return getToken();
    });
  };

  /* Calls attemptSsoSilent (by default forcing a login if required) to refresh idToken */
  const getRefreshedToken = (forceLogin = ForceLoginFallback.Always) => {
    isRefreshing = authModule
      ?.attemptSsoSilent(forceLogin)
      .then((account) => {
        if (!account) {
          console.error("No account found after ssoSilent");
          eraseToken();
        }
        return getToken();
      })
      .catch((err) => {
        console.error("Token renewal error");
        eraseToken();
        return null;
      });

    return isRefreshing;
  };

  const getToken = (): string | null => {
    if (!inMemoryJWT) {
      console.info("Token not available");
      return null;
    }

    if (isTokenExpired(inMemoryJWT)) {
      console.info("Token has expired");
      return null;
    }

    return inMemoryJWT;
  };

  const setToken = (token, delay) => {
    inMemoryJWT = token;
    refreshToken(delay);
    return true;
  };

  const eraseToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    window.localStorage.setItem(logoutEventName, Date.now().toString());
    return true;
  };

  /* This listener will allow to disconnect a session of ra started in another tab */
  window.addEventListener("storage", (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  return {
    eraseToken,
    getRefreshedToken,
    getToken,
    getAuthModule,
    setAuthModule,
    setToken,
    waitForTokenRefresh,
  };
};

export default inMemoryJWTManager();
