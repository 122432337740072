import { createTheme } from "@material-ui/core/styles";
import { Palette } from "./theme/palette";
import { MuiOverrides, RaOverrides } from "./theme/styles";

export const COLOUR_TRANSPOWER_BLUE: string = "#24bfff";
export const COLOUR_TRANSPOWER_DARK_BLUE: string = "#007399";
export const COLOUR_DARK_BLUE: string = "#13265C";
export const COLOUR_TP_RGBA: string = "rgba(0,115,153, 0.2)";
export const COLOUR_TP_RGBA6: string = "rgba(0,115,153, 0.6)";
export const COLOUR_TP_RGBA9: string = "rgba(0,115,153, 0.9)";
export const COLOUR_TRANSPOWER_GREEN: string = "#149414";
export const COLOUR_TRANSPOWER_DARK_GREEN: string = "#006C0B";
export const COLOUR_TRANSPOWER_AMBER: string = "#FF8C00";
export const COLOUR_TRANSPOWER_RED: string = "#CC1400";
export const COLOUR_TRANSPOWER_TURQ: string = "#0E7C61";
export const COLOUR_COMPLIANCE_SOFT_TONE_BLUE: string = "#004EFF";
export const COLOUR_ROW_HIGHLIGHT_RED: string = "#FFCCCB";

const tpTheme = {
  palette: Palette,
  overrides: { ...RaOverrides, ...MuiOverrides },
  shape: {
    borderRadius: 4,
  },
};

export const TPMaterialTheme = createTheme(tpTheme);

type CustomTheme = typeof tpTheme;
declare module "@material-ui/styles" {
  export interface DefaultTheme extends CustomTheme {}
}
